.lightTheme {
  box-sizing: border-box;
  min-height: var(--footer-mob);
  /* padding: 0 var(--gutter); */
  /* border-top: 1px solid transparent; */
  background-color: var(--main-100);
  color: var(--main-300);
  font-size: 12px;
  font-family: var(---font-main-medium);
  line-height: 1;
}
.darkTheme {
  composes: lightTheme;
  background-color: var(--main-900);
  border-top: 1px solid var(--main-700);
}
.container {
  border-top: 1px solid transparent;
  height: 100%;
  box-sizing: border-box;
  min-width: 272px;
  margin: auto;
  padding: 8px var(--gutter) 0;
}
.copyright {
  margin: 0;
  line-height: 1;
}
.author {
  text-align: right;
  margin: 0;
  line-height: 1;
}
.image {
  position: relative;
  top: 4px;
  margin: 0 0 0 0.5rem;
}
.signOfCopyright,
.signOfDivider {
  font-size: 24px;
}
.signOfDivider {
  opacity: 0.2;
}
.signOfCopyright {
  position: relative;
  top: 4px;
}
@media (min-width: 768px) {
  .lightTheme,
  .darkTheme {
    min-height: var(--footer-tablet);
  }
  .darkTheme {
    background-color: var(--main-100);
    color: var(--main-300);
    border-top: 1px solid transparent;
  }
  .container {
    min-height: 54px;
    padding: 8px var(--gutter);
    margin: 0;
    text-align: center;
  }
  .link {
    position: relative;
    top: -4px;
  }
  .signOfCopyright {
    top: 0;
  }
}

@media (min-width: 1080px) {
  .lightTheme,
  .darkTheme {
    min-height: var(--footer-desktop);
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .copyright {
    grid-column: 2/3;
    grid-row: 1/2;
    text-align: center;
  }
  .author {
    grid-column: 3/4;
    grid-row: 1/2;
    text-align: right;
  }
}
