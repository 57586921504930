.containerFluid {
  background-color: var(--main-900);
  color: var(--main-100);
}

.container {
  min-height: var(--header-mob);
  min-width: 272px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 768px) {
  .container {
    min-height: var(--header-tablet);
    padding: 0 1.8rem 0 0;
    /* width: calc(720 / 768 * 100%); */
  }
}

@media (min-width: 1080px) {
  .container {
    min-height: var(--header-desktop);
    /* max-width: 1392px; */
  }
}
