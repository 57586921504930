/* Switcher Panels */
.containerFluid {
  background-color: var(--main-200);
  display: flex;
  justify-content: space-between;
  box-shadow: var(--bs-dark);
}

.btnCollaps {
  background-color: transparent;
  outline: none;
  border: none;
  height: 35px;
  line-height: 35px;
  width: 33.33%;
  text-align: center;
  box-sizing: border-box;
  font-size: 12px;
  font-family: var(--font-second);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}
.btnCollapsActive {
  composes: btnCollaps;
  border-bottom-color: #fff;
}
.btnCollaps:hover {
  text-shadow: var(--ts);
  transition: 0.3s ease-in-out;
}

.TestRules {
  padding-top: 2rem;
  min-height: 10rem;
  /* background-color: var(--main-200); */
}

@media (min-width:768px) {
  .containerFluid {
    display: none;
  }
}

/* ========= Left Right Panel =========== */

/* Left Panel */
.LeftPanel,
.RightPanel {
  color: var(--main-700);
  min-height: calc(100vh - 2 * var(--gutter) - 50px - 70px);
  padding: 37px 40px 37px 37px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 2px 2px 6px 2px hsla(0, 0%, 0%, 0.05);
}

/* .LeftPanel {
  margin: var(--gutter) calc(var(--gutter) / 2) var(--gutter) var(--gutter);
}
.RightPanel {
  margin: var(--gutter) var(--gutter) var(--gutter)  calc(var(--gutter) / 2);
} */
.LeftPanelHidden,
.LeftTopPanelHidden,
.LeftBottomPanelHidden,
.RightPanelHidden,
.RightTopPanelHidden,
.RightBottomPanelHidden {
  display: none;
}

.PanelContainer,
.RightTopPanel {
  display: block;
}

.RightTopPanel {
  /* background-color: var(--main-900); */
  background-image: linear-gradient(
    rgb(255, 255, 255),
    rgb(255, 255, 255) 50%,
    var(--main-900) 50%,
    var(--main-900)
  );
  color: #fff;
}

@media (min-width: 768px) {
  .LeftPanel,
  .RightPanel {
    margin-bottom: var(--gutter);
    min-height: calc(100vh - 2 * var(--gutter) - 2 * 70px);
    padding: 37px 40px 37px 37px;
  }
  .LeftPanel {
    padding-top: 27px;
  }
  .LeftPanelHidden,
  .RightPanelHidden {
    composes: LeftPanel;
    display: block;
  }

  .LeftTopPanelHidden,
  .LeftBottomPanelHidden,
  .RightTopPanelHidden,
  .RightBottomPanelHidden {
    display: block;
    width: 100%;
  }
  .RightTopPanelHidden {
    background-color: var(--main-900);
    color: #fff;
  }
}
@media (min-width:1080px) {
  .container {
    display: flex;
    justify-content: center;
  }
  .LeftPanel,
  .RightPanel,
  .LeftPanelHidden,
  .RightPanelHidden {
    padding: 37px 40px 37px 37px;
    width: calc(50% - var(--gutter) * 1.5);
    margin-bottom: var(--gutter);
    margin-top: var(--gutter);
  }
  .LeftPanel {
    padding-top: 27px;
  }
  .LeftPanelHidden,
  .LeftPanel {
    margin-left: var(--gutter);
    margin-right: var(--gutter);
  }
  .RightPanelHidden,
  .RightPanel {
    margin-left: 0;
    margin-right: var(--gutter);
  }
}

.loader {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50% -50%);
}

.controls {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--gutter);
  padding: var(--gutter);
}

.editor {
  color: var(--acent-400);
}

.helpWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video {
  height: calc(100% - 60px);
}
.helpControls {
 display: flex;
 justify-content: center;
}