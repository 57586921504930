.containerFluid {
  width: 100%;
  min-height: calc(100vh - var(--header-mob) - var(--footer-mob));
  background-color: var(--main-900);
  margin: 0;
  background-image: url(../../images/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  /* max-width: 960px; */
  min-width: 320px;
  padding: 1rem;
  box-sizing: border-box;
}

.title {
  font-size: 22px;
  text-align: left;
  font-family: var(--font-second);
  color: #fff;
  font-weight: bold;
  line-height: 1.6;
  padding: 30px 10px 15px;
  letter-spacing: 0.6px;
  box-sizing: border-box;
  /* margin-bottom: 6vmax; */
  text-shadow: 0px 8px 43px hsla(24, 100%, 100%, 0.288);
  margin-top: 60px;
  margin-bottom: 85px;
}
.SupTitle {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.accentTitle {
  color: #f0bc44;
}

.link {
  display: block;
  margin: auto;
  width: 220px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background-color: var(--acent-300);
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-family: var(--font-main);
  font-weight: bold;
  box-shadow: 0px 8px 43px 0px hsla(24, 100%, 52%, 0.588);
}

.link:hover {
  box-shadow: 0px 4px 21px 0px hsla(24, 100%, 52%, 0.7);
}
.link:active {
  box-shadow: 0px 1px 2px 0px hsla(24, 100%, 52%, 0.7);
}


@media (min-width: 768px) {
  .link {
    margin: 0;
  }
  .title {
    max-width: 558px;
    /* margin: 0 auto 6vmax; */
    margin-top: 80px;
    margin-bottom: 25px;
  }
  .SupTitle {
    font-size: 38px;
  }
  .linkWrapper {
    max-width: 558px;
    margin-left: 12px;
  }

  .containerFluid {
    min-height: calc(100vh - var(--header-tablet) - var(--footer-tablet));
    background-image: url(../../images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  /* .container {
    height: calc(100vh - var(--header-tablet) - var(--footer-tablet));;
    background-image: url(../../images/bg.png);
    background-repeat: no-repeat;
    background-position:  bottom 60px left 30px;
  } */
}

@media (min-width: 1080px) {
  .containerFluid {
    min-height: calc(100vh - var(--header-desktop) - var(--footer-desktop));
  }
  .title {
    max-width: 100%;
    font-size: 36px;
  }
  .container {
    max-width: 1400px;
    margin: auto;
    padding-left: 100px;
  }
  .SupTitle {
    font-size: 65px;
  }
  /* .container {
    display: flex;
    justify-content: space-between;
    padding: 0 8.33%;
  } */
}
