body {
  background-color: #fff;
}
.container {
  margin: 0;
  box-shadow: 0 3px 3px 3px hsla(0, 0%, 0%, 0.2);
  box-sizing: border-box;
  height: calc(100vh - 50px - var(--footer-mob));
}

.formWrapper {
  width: 292px;
  margin: auto;
  background-color: #fff;
  padding: 64px 0 0;
  box-shadow: var(--bs);
}

.title {
  font-size: 15px;
  font-family: var(--font-second);
  color: #000;
  font-weight: bold;
  line-height: 1.4;
  text-align: left;
  margin-bottom: 24px;
}

.btn {
  border-width: 1px;
  border-color: var(--main-800);
  border-style: solid;
  max-width: 130px;
  min-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  font-size: 12px;
  font-family: var(--font-main);
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  background: transparent;
}

.btnAcent {
  composes: btn;
  margin-left: 8px;
  border-color: var(--acent-300);
  background-color: var(--acent-300);
  color: #fff;
}

.consent {
  font-size: 11px;
  font-family: var(--font-main);
  color: var(--acent-400);
  text-decoration: underline;
  line-height: 1;
  text-align: center;
  margin: 24px 0;
}

@media (min-width:768px) {
  .container {
    max-width: 534px;
    min-height: 372px;
    max-height: 600px;
    height: auto;
    margin: 115px auto;
    outline: 14px solid var(--main-100);
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding-top: 24px;
}
