.menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}

.menuVertical {
  background-color: var(--main-900);
  position: fixed;
  z-index: 1000;
  top: calc(50px + var(--gutter));
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 80%;
  border-radius: 6px;
  border-bottom: 1px solid var(--main-200);
  opacity: 1;
  transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out 0.25s;
}

.menuVerticalClose {
  composes: menuVertical;
  top: -1000px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out, top 0.25s ease-in-out 0.1s;
}

.menuItem {
  margin: 0 2.5px;
  display: none;
}
/* .menuItemProgress {
  font-size: 14px;
  font-family: var(--font-second);
  color: var(--txt-100);
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: auto 1rem;
} */
.paginationWrapper {
  font-size: 24px;
  font-family: monospace;
  color: var(--txt-100);
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: auto auto auto 1rem;
  justify-content: center;
}
.menuItem:hover .linkText {
  color: #fff;
}
.menuVertical > .menuItem {
  padding: 1rem 0;
  display: block;
  border-bottom: 1px solid var(--main-200);
}
.menuVertical > .menuItem:last-of-type {
  border-bottom: none;
}
.logoWrapper {
  composes: menuItem;
  margin-right: auto;
  display: block;
}
.burgerButtonWrapper {
  display: flex;
  margin-right: var(--gutter);
  align-self: center;
  padding: 1rem;
}

.menuLink {
  color: var(--main-200);
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--font-second);
  font-size: 1rem;
  font-weight: bold;
  background-color: inherit;
  transition: 0.5s color ease-in-out;
}
.linkText {
  font-size: 14px;
  font-family: var(--font-second);
  color: var(--txt-100);
  font-weight: bold;
  transition: 0.4s color ease;
}
.menuItemDecor {
  color: var(--txt-100);
  font-size: 22px;
  line-height: 50px;
}
.menuItemIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.logoLink {
  composes: menuLink;
  display: flex;
  border-radius: 6px;
  padding: 0 12px;
}

/* .linkAnimate {
  position: relative;
} */
.logo {
  display: block;
  margin: auto;
}
.logoLink:hover {
  box-shadow: var(--bs-light);
  cursor: pointer;
}

.menuLink:hover {
  color: #fff;
  cursor: pointer;
}

@media (min-width: 768px) {
  .menuItem {
    display: flex;
    align-items: center;
  }
  .burgerButtonWrapper {
    display: none;
  }
  .menuVertical {
    display: none;
  }
  .logoLink:hover {
    cursor: inherit;
  }
  .menuLink:hover {
    cursor: inherit;
  }
}
