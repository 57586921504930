/* .containerFluid {
} */
.theme {
  font-size: 14px;
  text-align: left;
  font-family: var(--font-second);
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid hsla(212, 55%, 14%, 0.051);
}

.themeInline {
  background-color: var(--main-100);
}

.topic {
  /* font-size: 21px; */
  font-size: 32px;
  font-family: var(--font-second);
  color: #000;
  font-weight: bold;
  /* margin: 1rem 0; */
  margin: 0;
}

/* p,
li, */
.description {
  font-size: 14px;
  font-family: var(--font-main);
  color: var(--main-300);
  line-height: 2;
}

h4 {
  font-size: 14px;
  font-family: var(--font-second);
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  margin: 1rem 0;
}
.titleAccent {
  color: var(--acent-400);
}
em {
  color: var(--main-200);
  font-weight: bold;
  font-style: inherit;
}
pre {
  margin: 1rem 0;
}

.orderedList {
  margin-left: 1rem;
}

.summary {
  font-style: italic;
  font-size: 12px;
  text-align: right;
  position: relative;
  bottom: -30px;
  list-style-type: none !important;
  color: transparent;
}

.hide {
  display: none;
}

.title {
  padding-right: 30px;
  margin:  0 1rem 0 0;
}

details, summary {
  border: none;
  outline: none;
}

.titleExample {
  margin-top: 0;
  color: var(--acent-400);
}

.details[open] + .titleExample {
  margin-top: 1rem;
}