.icon {
    margin: auto .4rem;
    font-size: 16px;
}

.forward,
.back {
    position: relative;
    top: .3rem;
    fill: var(--main-200);
}

.disabled {
    position: relative;
    top: .3rem;
    fill: var(--txt-100);
}

.current, .prev, .next {
    margin: auto .4rem;
}

.item {
    font-size: 14px;
    font-family: monospace;
}

.activeItem {
    composes: item;
    color: var(--main-200);
}

.iconDisabled{
    font-size:16px;
    fill:  var(--txt-100);
}

@media (min-width: 400px) {
    .icon {
        margin: auto .75rem;
        font-size: 24px;
    }
    .current, .prev, .next {
        margin: auto .75rem;
    }
    .item {
        font-size: 20px;
    }
    .iconDisabled{
        font-size: 24px;
    }
}