.controlsBtn {
  padding: 10px 24px;
  border: none;
  outline: none;
  background-color: var(--main-700);
  color: var(--main-300);
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
}
.controlsBtnActive,
.controlsBtn {
  /* margin: 1rem; */
}
.controlsText {
  margin-right: 10px;
  font-size: 12px;
  font-family: var(--font-main);
  font-weight: bold;
  text-transform: uppercase;
  display: none;
}

.controlsBtn:hover {
  background-color: var(--main-500);
  color: #fff;
}

.controlsBtn svg {
  fill: var(--main-300);
  /* display: none; */
}

.controlsBtn:hover svg {
  fill: #fff;
}

.controlsBtnActive {
  composes: controlsBtn;
  background-color: var(--acent-300);
  color: #fff;
}

.controlsBtnActive svg {
  fill: #fff;
  /* display: none; */
}

.controlsTextHide {
  composes: controlsText;
  display: none;
}
@media (min-width: 768px) {
  .controlsTextHide {
    display: initial;
  }
  .controlsText {
    display: block;
  }
}
/* @media (min-width: 1300px) { */
@media (min-width: 1080px) {
  .controlsBtn svg,
  .controlsBtnActive svg {
    display: block;
  }
}
