.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  font-size: 14px;
  font-family: var(--font-second);
  color: green;
  font-weight: bold;
  line-height: 1.286;
  margin: 1rem 0;
  padding: 0 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  vertical-align: middle;
  border-bottom-color: var(--main-300);

  /* width: 80%; */
  flex-basis: 80%;
  display: inline-block;
}
.input::placeholder {
  color: var(--main-200);
}
.span {
  flex-basis: 20%;
  display: inline-block;
  vertical-align: middle;

  font-size: 13px;
  font-family: var(--font-second);
  color: var(--main-300);
  line-height: 1.385;
  text-align: left;
}
.asterisk {
  color: var(--acent-300);
}


.input[type='email']:focus:invalid,
.input[type='password']:focus:invalid {
  border-bottom-color: red;
  transition: border-bottom-color 5s;
}

.input[type='email']:focus:valid,
.input[type='password']:focus:valid {
  border-bottom-color: green;
  color: green;
  transition: border-bottom-color 0.4s;
}
