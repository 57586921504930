.orderedList {
  margin-left: 1rem;
}
em {
  /* color: var(--main-200); */
  color: var(--main-900);
  font-weight: bold;
  font-style: inherit;
}
pre {
  margin: 1rem 0;
}
h4 {
  font-size: 14px;
  font-family: var(--font-second);
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  margin: 1rem 0;
}
.titleAccent {
  color: var(--acent-400);
}
p,
li {
  font-size: 14px;
  font-family: var(--font-main);
  color: var(--main-300);
  line-height: 1.9;
  margin-bottom: .5rem;
}
.listStyleNone {
  list-style-type: none;
}

.taskTopic {
  color: var(--main-800);
  font-weight: bold;
}
.taskTopic em {
  font-weight: normal;
}
.testItem {
 color: var(--acent-400);
}

.testItemText {
  color: var(--main-300);
}

.orderedList {
  margin-left: 1rem;
}