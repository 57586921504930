.container {
  max-width: 355px;
  margin:  24px auto;
  border: 1px #f00 solid;
  text-align: center;
}

.info {
  font-size: 20px;
  font-family: var(--font-second);
  color: #000;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 23px;
}
.title {
  font-size: 14px;
  font-family: var(--font-second);
  color: var(--main-300);
  line-height: 1;
  text-align: center;
  margin: 0 0 8px 0;
}
.message {
  font-size: 12px;
  font-family: 'Acrom';
  color: #f00;
  line-height: 1;
  text-align: center;
  margin-bottom: 20px;
}
.btnWrapper {
  display: flex;
  justify-content: center;
}
.btn {
  padding: 10px 28px;
  border: none;
  outline: none;
  background-color: var(--main-700);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: var(--font-main);
  color: var(--main-200);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2;
  margin-bottom: 23px;
}
/* .btn:hover {
  box-shadow: var(--bs-light-hover);
}
.btn:active {
  box-shadow: var(--bs-light-active);
} */
